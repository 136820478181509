var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "main",
    [
      !_vm.isLoading && !Object.keys(_vm.contract).length
        ? _c(
            "h1",
            {
              staticClass: "title null-hint",
            },
            [_vm._v("找不到對應方案合約資料")]
          )
        : _vm._e(),
      Object.keys(_vm.contract).length
        ? _c(
            "div",
            {
              staticClass: "hasContract",
            },
            [
              _c(
                "div",
                {
                  staticClass: "contract-card rule",
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "title",
                    },
                    [_vm._v("學習約定條款與上課聲明")]
                  ),
                  _c(
                    "h2",
                    {
                      staticClass: "product name",
                    },
                    [_vm._v(_vm._s(_vm.contract.productName))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v("學員名字: "),
                          _c(
                            "span",
                            {
                              staticClass: "chineseName",
                            },
                            [_vm._v(_vm._s(_vm.vip.chinese_name))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "englishName",
                            },
                            [_vm._v(" (" + _vm._s(_vm.vip.english_name) + ")")]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v(
                            "購買堂數: " +
                              _vm._s(_vm.contract.numSession) +
                              " 堂"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v(
                            "課程期間: " +
                              _vm._s(_vm.contract.origTerm) +
                              " 個月"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v(
                            "贈送堂數: " +
                              _vm._s(_vm.contract.giftSession) +
                              " 堂"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v(
                            "贈送期間: " +
                              _vm._s(_vm.contract.giftTerm) +
                              " 個月"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v(
                            "課程總堂數: " +
                              _vm._s(_vm.contract.totalSession) +
                              " 堂"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v(
                            "總課程期間: " +
                              _vm._s(_vm.contract.totalTerm) +
                              " 個月"
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v("學習期間: "),
                          _c(
                            "span",
                            {
                              staticClass: "learnTime",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.contract.learningTime[0].learningTime
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [_vm._v("方案金額: " + _vm._s(_vm.contract.origPrice))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "detail",
                        },
                        [
                          _vm._v(
                            "總折扣金額: " +
                              _vm._s(_vm.discountSum(_vm.contract))
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "product price",
                    },
                    [_vm._v("課程優惠價 $" + _vm._s(_vm.contract.sellingPrice))]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "contract-card",
                },
                [
                  _c("div", {
                    staticClass: "v-html",
                    domProps: {
                      innerHTML: _vm._s(_vm.cleanStyle(_vm.contract.contract)),
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "text-center",
                    },
                    [
                      _vm._v("您已於 "),
                      _c(
                        "span",
                        {
                          staticClass: "learnRule",
                        },
                        [_vm._v(_vm._s(_vm.contract.learnRule))]
                      ),
                      _vm._v(" 審慎閱讀完畢並同意學習條款之內容"),
                    ]
                  ),
                  _c("img", {
                    staticClass: "jr-logo",
                    attrs: {
                      src: "https://cdn3.wuwow.tw/arts/contract/wuwow-junior-logo.png",
                      alt: "jr-logo",
                    },
                  }),
                  _c("img", {
                    staticClass: "contract-seal",
                    attrs: {
                      src: "https://cdn3.wuwow.tw/arts/contract/lioshutan-contract-seal.png",
                      alt: "六書合約印章",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      !_vm.isLoading &&
      !Object.keys(_vm.contract).length &&
      _vm.newContract === false
        ? [
            _c(
              "p",
              {
                staticClass: "title",
              },
              [_vm._v(" WUWOW Jr 上課聲明")]
            ),
            _c(
              "p",
              {
                staticClass: "text-center",
              },
              [_vm._v("（ 不適用 Wushare 學員）")]
            ),
            _vm._m(0),
          ]
        : _vm._e(),
      !_vm.isLoading &&
      !Object.keys(_vm.contract).length &&
      _vm.newContract === true
        ? [
            _c(
              "p",
              {
                staticClass: "title",
              },
              [_vm._v("WUWOW Jr 上課聲明")]
            ),
            _c(
              "p",
              {
                staticClass: "text-center",
              },
              [_vm._v(" （ 不適用 Wushare 學員）")]
            ),
            _vm._m(1),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "section",
      {
        staticClass: "v-html",
      },
      [
        _c("div", [
          _c("p", [_vm._v("服務內容：")]),
          _c("div", [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "WUWOW Jr 提供本服務之網站網址：https: //www.wuwowjr.tw/"
                ),
              ]),
              _c("li", [
                _vm._v("WUWOW Jr 提供本服務之適⽤對象：四歲以上；十五歲以下者"),
              ]),
              _c("li", [
                _vm._v(
                  "WUWOW Jr 提供本服務之教學內容：依單元主題教材內容⽽定，除上傳自備教材外，學員不可排除或要求略過WUWOW Jr原定順序之教材，且不得排除任一授課顧問。"
                ),
              ]),
              _c("li", [
                _vm._v("情境主題教材，學員、教材、顧問三方適性分析完美媒合。"),
              ]),
              _c("li", [
                _vm._v(
                  "每堂課程後，提供完整六大面向英文程度報告，隨時掌握學習進度。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "每堂課程後，可針對顧問、教材、系統三方面作評鑑反饋，為您的學習品質把關。"
                ),
              ]),
              _c("li", [_vm._v("課後錄影檔7天內免費觀看複習。")]),
              _c("li", [
                _vm._v(
                  "課程專屬服務，學員可寫信寄至 jrpm@lioshutan.com 諮詢學習疑問。"
                ),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
        _c("div", [
          _c("p", [_vm._v("課程種類：")]),
          _c("div", [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "【一般課程】：專屬一位學員的家教班級，由系統媒合WUWOW Jr教材；如欲指定顧問，需於72小時前預約，每次扣除課堂數為一堂。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "【客製化課程】：可上傳自備教材並同時指定顧問，需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消課程。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "【特殊時段申請】：可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材；需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消。顧問將於課前36小時於系統回覆是否接受加開申請，如未申請成功，則不需計算堂數。"
                ),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
        _c("div", [
          _c("p", [_vm._v("訂課及取消方式：")]),
          _c("div", [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "開課時段為每半小時開課，每堂課程25分鐘。開課時段分為周一至周五中午12:30至晚上21:00；每周六、周日與每年七、八月整月份開課時間為早上09:00至晚上21:00。每年寒假期間開課時間為早上09:00至晚上21:00，確切月份日期將另行公告於官網。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "09:00-12:00的課程需於前日21:00前完成預約；12:30-18:00的課程需於當日中午11:00前完成預約；18:30-21:00的課程需於當日下午17:00前完成預約。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "【一般課程-指定顧問、客製化課程、特殊時段申請】需於課前72小時預約。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "預約【一般課程】可於開課前15分鐘登入個人學習系統取消課程，不扣堂數。"
                ),
              ]),
              _c("li", [
                _vm._v("預約【客製化課程、特殊時段申請】不得取消課程。"),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
        _c("div", [
          _c("p", [_vm._v("使用規範：")]),
          _c("div", [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "進入教室時間：開課前5分鐘~開課後3分鐘，請準時進入教室。如超過此區間則不得進入教室，且堂數照常計算。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "請勿於課程期間分心、使用手機或與旁人聊天，影響課程進行。"
                ),
              ]),
              _c("li", [_vm._v("需使用耳機與麥克風，確保收音品質。")]),
              _c("li", [
                _vm._v(
                  "請勿擅自破壞、修改網路內容或蓄意散佈干擾網路系統正常運作之電腦程式（含病毒）。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "不得利用線上系統施行言語或文字有性別、種族、政治、宗教歧視或散佈不實、不當（騷擾、謾罵、攻擊、恐嚇、毀謗、侵犯他人隱私、色情或其他違反公序良俗…等）之言論、文字、圖片或影像或其他不受歡迎之行為。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "不得將會籍資格與可使用堂數進行出售、贈與、拍賣、出借等方式予以他人使用，影響 WUWOW 作業系統及其他會員權益，如經發現，WUWOW 得逕行停止提供課程服務。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "承接者未購買單一合約，故不得申請終止學習、不得申請暫停學習期限，且承接之堂數與期限皆不可轉回主約人或異動。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "如學員於契約結束日仍有剩餘堂數未使用，WUWOW Jr同意學員或承接者得以$1,000 延長一個月學習期限（主約人及承接者須各自付費辦理，雙方付費延長之上限共六個月），或以4堂課程兌換一個月學習期限(主約人及承接者須各自兌換辦理，雙方可兌換之上限共24堂)。契約延長月份需從到期日開始接續，不得中斷期限。延期需於到期日前提出申請，到期日後則不得再提出申請。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "若 WUWOW 因相關電信業者網際網路系統故障，或相關系統設備損壞維修、維護或更換等障礙因素造成服務中斷，WUWOW將於系統公告，並於系統恢復後五天內補回承接分享者已扣之堂數;同樣，承接分享者需為天災以外之環境因素(如網路頻寬、載具與語音相關設備等)負責，確保個人學習品質狀況。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "承接分享者同意無償提供註冊登載於 WUWOW 之個人資料予 WUWOW 蒐集電腦處理及作為接收 WUWOW 發行之電子報、產品資訊、服務更新等各項服務與資訊之用。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "雙方同意因本合約所生之爭議而申請法院調解或提起訴訟請求時，以中華民國之法律為準據法，並以臺灣臺中地方法院為第一審管轄法院。"
                ),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
        _c("div", [
          _c("p", [_vm._v("設備規格：")]),
          _c("div", [
            _c("table", [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_c("p", [_vm._v("視訊系統")])]),
                  _c("td", [
                    _c("p", [_vm._v("電腦")]),
                    _c("ul", [
                      _c("li", [_vm._v("Mac OS 10.12+")]),
                      _c("li", [_vm._v("Windows 7+")]),
                    ]),
                    _c("p", [_vm._v("手機")]),
                    _c("ul", [
                      _c("li", [_vm._v("Android 5.0+")]),
                      _c("li", [_vm._v("IOS 9.0+")]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_c("p", [_vm._v("瀏覽官網")])]),
                  _c("td", [
                    _c("ul", [
                      _c("li", [_vm._v("Chrome 86+")]),
                      _c("li", [_vm._v("Safari 13.1.1+")]),
                      _c("li", [_vm._v("Firefox 81.0.2+")]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_c("p", [_vm._v("網路速度")])]),
                  _c("td", [
                    _c("ul", [
                      _c("li", [_vm._v("光纖網路 上傳6MB+ 下載6MB+")]),
                      _c("li", [_vm._v("⾏動網路 4G+")]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "section",
      {
        staticClass: "v-html",
      },
      [
        _c("div", [
          _c("p", [_vm._v("服務內容：")]),
          _c("div", [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "WUWOW Jr 提供本服務之網站網址：https: //www.wuwowjr.tw/"
                ),
              ]),
              _c("li", [
                _vm._v("WUWOW Jr 提供本服務之適⽤對象：四歲以上；十五歲以下者"),
              ]),
              _c("li", [
                _vm._v(
                  "WUWOW Jr 提供本服務之教學內容：依單元主題教材內容⽽定，除上傳自備教材外，學員不可排除或要求略過WUWOW Jr原定順序之教材，且不得排除任一授課顧問。"
                ),
              ]),
              _c("li", [
                _vm._v("情境主題教材，學員、教材、顧問三方適性分析完美媒合。"),
              ]),
              _c("li", [
                _vm._v(
                  "每堂課程後，提供完整六大面向英文程度報告，隨時掌握學習進度。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "每堂課程後，可針對顧問、教材、系統三方面作評鑑反饋，為您的學習品質把關。"
                ),
              ]),
              _c("li", [_vm._v("課後錄影檔7天內免費觀看複習。")]),
              _c("li", [
                _vm._v(
                  "課程專屬服務，學員可寫信寄至 jrpm@lioshutan.com 諮詢學習疑問。"
                ),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
        _c("div", [
          _c("p", [_vm._v("課程種類：")]),
          _c("div", [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "【一般課程】：專屬一位學員的家教班級，由系統媒合WUWOW Jr教材；如欲指定顧問，需於72小時前預約，每次扣除課堂數為一堂。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "【客製化課程】：可上傳自備教材並同時指定顧問，需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消課程。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "【特殊時段申請】：可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材；需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消。顧問將於課前36小時於系 是否接受加開申請，如未申請成功，則不需計算堂數。"
                ),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
        _c("div", [
          _c("p", [_vm._v("訂課及取消方式：")]),
          _c("div", [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "開課時段為每半小時開課，每堂課程25分鐘。開課時段分為周一至周五中午12:30至晚上21:00；每周六、周日與每年七、八月整月份開課時間為早上09:00至晚上21:00。每年寒假期間開課時間為早上09:00至晚上21:00，確切月 將另行公告於官網。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "09:00-12:00的課程需於前日21:00前完成預約；12:30-18:00的課程需於當日中午11:00前完成預約；18:30-21:00的課程需於當日下午17:00前完成預約。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "【一般課程-指定顧問、客製化課程、特殊時段申請】需於課前72小時預約。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "預約【一般課程】可於開課前15分鐘登入個人學習系統取消課程，不扣堂數。"
                ),
              ]),
              _c("li", [
                _vm._v("預約【客製化課程、特殊時段申請】不得取消課程。"),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
        _c("div", [
          _c("p", [_vm._v("使用規範：")]),
          _c("div", [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "進入教室時間：開課前5分鐘~開課後3分鐘，請準時進入教室。如超過此區間則不得進入教室，且堂數照常計算。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "請勿於課程期間分心、使用手機或與旁人聊天，影響課程進行。"
                ),
              ]),
              _c("li", [_vm._v("需使用耳機與麥克風，確保收音品質。")]),
              _c("li", [
                _vm._v(
                  "請勿擅自破壞、修改網路內容或蓄意散佈干擾網路系統正常運作之電腦程式（含病毒）。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "不得利用線上系統施行言語或文字有性別、種族、政治、宗教歧視或散佈不實、不當（騷擾、謾罵、攻擊、恐嚇、毀謗、侵犯他人隱私、色情或其他違反公序良俗…等）之言論、文字、圖片或影像或其他不受歡迎之行為。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "不得將會籍資格與可使用堂數進行出售、贈與、拍賣、出借等方式予以他人使用，影響 WUWOW 作業系統及其他會員權益，如經發現，WUWOW 得逕行停止提供課程服務。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "承接者未購買單一合約，故不得申請終止學習、不得申請暫停學習期限，且承接之堂數與期限皆不可轉回主約人或異動。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "如學員於契約結束日仍有剩餘堂數未使用，WUWOW Jr同意學員或承接者得以$1,000 延長一個月學習期限（主約人及承接者須各自付費辦理，雙方付費延長之上限共六個月），或以4堂課程兌換一個月學習期限(主約人及承接者須各 辦理，雙方可兌換之上限共24堂)。契約延長月份需從到期日開始接續，不得中斷期限。延期需於到期日前提出申請，到期日後則不得再提出申請。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "若 WUWOW 因相關電信業者網際網路系統故障，或相關系統設備損壞維修、維護或更換等障礙因素造成服務中斷，WUWOW將於系統公告，並於系統恢復後五天內補回承接分享者已扣之堂數;同樣，承接分享者需為天災以外之環境因素( 頻寬、載具與語音相關設備等)負責，確保個人學習品質狀況。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "承接分享者同意無償提供註冊登載於 WUWOW 之個人資料予 WUWOW 蒐集電腦處理及作為接收 WUWOW 發行之電子報、產品資訊、服務更新等各項服務與資訊之用。"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "雙方同意因本合約所生之爭議而申請法院調解或提起訴訟請求時，以中華民國之法律為準據法，並以臺灣臺中地方法院為第一審管轄法院。"
                ),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
        _c("div", [
          _c("p", [_vm._v("設備規格：")]),
          _c("div", [
            _c("table", [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_c("p", [_vm._v("視訊系統")])]),
                  _c("td", [
                    _c("p", [_vm._v("電腦")]),
                    _c("ul", [
                      _c("li", [_vm._v("Mac OS 10.12+")]),
                      _c("li", [_vm._v("Windows 7+")]),
                    ]),
                    _c("p", [_vm._v("手機")]),
                    _c("ul", [
                      _c("li", [_vm._v("Android 5.0+")]),
                      _c("li", [_vm._v("IOS 9.0+")]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_c("p", [_vm._v("瀏覽官網")])]),
                  _c("td", [
                    _c("ul", [
                      _c("li", [_vm._v("Chrome 86+")]),
                      _c("li", [_vm._v("Safari 13.1.1+")]),
                      _c("li", [_vm._v("Firefox 81.0.2+")]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_c("p", [_vm._v("網路速度")])]),
                  _c("td", [
                    _c("ul", [
                      _c("li", [_vm._v("光纖網路 上傳6MB+ 下載6MB+")]),
                      _c("li", [_vm._v("⾏動網路 4G+")]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("p"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }